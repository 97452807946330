import React from "react";
import "../components/LandingPage.scss";

type Props = {
    isMuted: boolean
}

const audioBkgd = new Audio("https://d3fgy724b6wl6q.cloudfront.net/music/landing_bgm.wav");

const LandingPage:React.FC<Props> = (props) => {
    const [isMuted, setIsMuted] = React.useState<boolean>(props.isMuted);
    audioBkgd.addEventListener('ended', function () {
        this.currentTime = 0;
        this.play();
      }, false);
    const toggleSound = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setIsMuted(!isMuted);
        audioBkgd.play();
        audioBkgd.muted = !isMuted;
      };
    const  readLitepaperClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const url = 'https://thedoodies.gitbook.io/docs';
        window.open(url, '_blank');
      };

    return (
        <div className="container-fluid landing-page">
            <div className="content">
                <div className="volume-container row-fluid">
                    <a className="social-icon" title="Volume" href="#" rel="noopener" onClick={toggleSound} >
                        <img title="Volume" alt="Volume" className={isMuted ? 'muted' : ''}/>
                    </a>
                </div>
                <div className="logo row-fluid">
                    <video  autoPlay muted playsInline preload="metadata" className="video-lg" >
                        <source src="https://d3fgy724b6wl6q.cloudfront.net/videos/animated_logo.webm#t=0.6" type="video/webm"/>
                        <source src="https://d3fgy724b6wl6q.cloudfront.net/videos/animated_logo.mov" type="video/quicktime"/>
                        <p>Your browser doesn't support HTML5 video tag.</p>
                    </video>
                    <video autoPlay muted playsInline preload="metadata" className="video-sm" >
                        <source src="https://d3fgy724b6wl6q.cloudfront.net/videos/animated_logo_mobile.webm#t=0.6" type="video/webm"/>
                        <source src="https://d3fgy724b6wl6q.cloudfront.net/videos/animated_logo_mobile.mov" type="video/quicktime"/>
                        <p>Your browser doesn't support HTML5 video tag.</p>
                    </video>
                </div>
                <div className="content-footer column-fluid" >
                    <div className="btn-wrapper row-fluid">
                        <button className="btn btn-primary text-center text-uppercase" onClick={readLitepaperClicked}>Read Litepaper</button>
                    </div>
                    <div className="social-container row-fluid">
                        <a className="social-icon" title="Twitter" href="https://twitter.com/DoodiesD" target="_blank" rel="noreferrer">
                            <img title="Twitter" alt="Twitter" src="https://d3fgy724b6wl6q.cloudfront.net/images/twitter_logo.png"/>
                        </a>
                        <a className="social-icon" title="Facebook" href="https://www.facebook.com/the.doodies.global" target="_blank" rel="noreferrer">
                            <img title="Facebook" alt="Facebook" src="https://d3fgy724b6wl6q.cloudfront.net/images/facebook_logo.png"/>
                        </a>
                        <a className="social-icon" title="Instagram" href="https://www.instagram.com/the.doodies/?hl=en" target="_blank" rel="noreferrer">
                        <   img title="Instagram" alt="Instagram" src="https://d3fgy724b6wl6q.cloudfront.net/images/instagram_logo.png"/>
                        </a>
                        <a className="social-icon" title="Join the Conversation on Discord" href="https://discord.gg/g5s5DCdec4" target="_blank" rel="noreferrer">
                            <img title="Discord" alt="Discord" src="https://d3fgy724b6wl6q.cloudfront.net/images/discord_logo.png"/>
                        </a>
                    </div>
                    </div>
            </div>
            <div className="mountain-image mountain-zoom-out">
                <img alt="mountain" src="https://d3fgy724b6wl6q.cloudfront.net/images/front_mountain_1920x1080.png" className="mountain-front"/>
                <img alt="mountain" src="https://d3fgy724b6wl6q.cloudfront.net/images/back_mountain_1920x1080.png"/>
            </div>
        </div>
    );
};

export default LandingPage;